@use "@material/theme";
@use "@material/typography";
@use "@material/button/mdc-button";
@use "@material/button";
@use "@material/form-field";
@use "@material/list/mdc-list";
@use "@material/card";
@use "@material/checkbox";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/textfield/mdc-text-field";
@use "@material/select/mdc-select";
@use "@material/select/styles" as select-styles;
@use "@material/banner";
@use "@material/banner/styles";
@use "@material/dialog";

@include form-field.core-styles;
@include checkbox.core-styles;
@include card.core-styles;
@include dialog.core-styles;

h1 {
  @include typography.typography(headline3);
}

h2 {
  @include typography.typography(headline5);
}

h3 {
  @include typography.typography(headline5);
}

h4,
h5,
h6 {
  @include typography.typography(headline5);
}

a,
div,
label,
li,
p,
span,
td,
th,
.mdc-data-table__cell {
  @include typography.typography(body1);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

*,
::before,
::after {
  box-sizing: border-box;
}

.checkbox-list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 0.5rem;
  padding: 0.25rem 0;

  label {
    font-size: larger;
  }

  .mdc-checkbox .mdc-checkbox__background {
    zoom: 1.25;
    transform: scale(1.25);
    -ms-transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -o-transform: scale(1.25);
    -moz-transform: scale(1.25);
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
  }
}

.hide {
  display: none !important;
}
